define("discourse/plugins/discourse-ai/discourse/lib/ai-topic-summary", ["exports", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/formatter", "discourse/lib/text"], function (_exports, _tracking, _ajax, _formatter, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AiTopicSummary = _exports.default = (_class = class AiTopicSummary {
    constructor() {
      _initializerDefineProperty(this, "text", _descriptor, this);
      _initializerDefineProperty(this, "summarizedOn", _descriptor2, this);
      _initializerDefineProperty(this, "summarizedBy", _descriptor3, this);
      _initializerDefineProperty(this, "newPostsSinceSummary", _descriptor4, this);
      _initializerDefineProperty(this, "outdated", _descriptor5, this);
      _initializerDefineProperty(this, "canRegenerate", _descriptor6, this);
      _initializerDefineProperty(this, "regenerated", _descriptor7, this);
      _initializerDefineProperty(this, "showSummaryBox", _descriptor8, this);
      _initializerDefineProperty(this, "canCollapseSummary", _descriptor9, this);
      _initializerDefineProperty(this, "loadingSummary", _descriptor10, this);
    }
    processUpdate(update) {
      const topicSummary = update.ai_topic_summary;
      return (0, _text.cook)(topicSummary.summarized_text).then(cooked => {
        this.text = cooked;
        this.loading = false;
      }).then(() => {
        if (update.done) {
          this.summarizedOn = (0, _formatter.shortDateNoYear)(topicSummary.summarized_on);
          this.summarizedBy = topicSummary.algorithm;
          this.newPostsSinceSummary = topicSummary.new_posts_since_summary;
          this.outdated = topicSummary.outdated;
          this.newPostsSinceSummary = topicSummary.new_posts_since_summary;
          this.canRegenerate = topicSummary.outdated && topicSummary.can_regenerate;
        }
      });
    }
    collapse() {
      this.showSummaryBox = false;
      this.canCollapseSummary = false;
    }
    generateSummary(currentUser, topicId) {
      this.showSummaryBox = true;
      if (this.text && !this.canRegenerate) {
        this.canCollapseSummary = false;
        return;
      }
      let fetchURL = `/discourse-ai/summarization/t/${topicId}?`;
      if (currentUser) {
        fetchURL += `stream=true`;
        if (this.canRegenerate) {
          fetchURL += "&skip_age_check=true";
        }
      }
      this.loading = true;
      return (0, _ajax.ajax)(fetchURL).then(data => {
        if (!currentUser) {
          data.done = true;
          this.processUpdate(data);
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "text", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "summarizedOn", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "summarizedBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newPostsSinceSummary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "outdated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "canRegenerate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "regenerated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showSummaryBox", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "canCollapseSummary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "loadingSummary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
});