define("discourse/plugins/discourse-ai/discourse/components/modal/ai-tool-test-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/lib/utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _dButton, _dModal, _ajax, _ajaxError, _discourseI18n, _utilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _AiToolTestModal;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AiToolTestModal = _exports.default = (_class = (_AiToolTestModal = class AiToolTestModal extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "testResult", _descriptor, this);
      _initializerDefineProperty(this, "isLoading", _descriptor2, this);
      _defineProperty(this, "parameterValues", {});
    }
    updateParameter(name1, event1) {
      this.parameterValues[name1] = event1.target.value;
    }
    async runTest() {
      this.isLoading = true;
      try {
        const response1 = await (0, _ajax.ajax)("/admin/plugins/discourse-ai/ai-tools/test.json", {
          type: "POST",
          data: JSON.stringify({
            ai_tool: this.args.model.tool,
            parameters: this.parameterValues
          }),
          contentType: "application/json"
        });
        this.testResult = (0, _utilities.jsonToHtml)(response1.output);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this.isLoading = false;
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @title={{I18n.t "discourse_ai.tools.test_modal.title"}}
        @closeModal={{@closeModal}}
        @bodyClass="ai-tool-test-modal__body"
        class="ai-tool-test-modal"
      >
        <:body>
          {{#each @model.tool.parameters as |param|}}
            <div class="control-group">
              <label>{{param.name}}</label>
              <input
                {{on "input" (fn this.updateParameter param.name)}}
                name={{param.name}}
                type="text"
              />
            </div>
          {{/each}}
  
          {{#if this.testResult}}
            <div class="ai-tool-test-modal__test-result">
              <h3>{{I18n.t "discourse_ai.tools.test_modal.result"}}</h3>
              <div>{{this.testResult}}</div>
            </div>
          {{/if}}
        </:body>
  
        <:footer>
          <DButton
            @action={{this.runTest}}
            @label="discourse_ai.tools.test_modal.run"
            @disabled={{this.isLoading}}
            class="btn-primary ai-tool-test-modal__run-button"
          />
        </:footer>
      </DModal>
    
  */
  {
    "id": "aClC59Lc",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"ai-tool-test-modal\"]],[[\"@title\",\"@closeModal\",\"@bodyClass\"],[[28,[32,1,[\"t\"]],[\"discourse_ai.tools.test_modal.title\"],null],[30,1],\"ai-tool-test-modal__body\"]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2,[\"tool\",\"parameters\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n            \"],[10,\"label\"],[12],[1,[30,3,[\"name\"]]],[13],[1,\"\\n            \"],[11,\"input\"],[16,3,[30,3,[\"name\"]]],[24,4,\"text\"],[4,[32,2],[\"input\",[28,[32,3],[[30,0,[\"updateParameter\"]],[30,3,[\"name\"]]],null]],null],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"\\n\"],[41,[30,0,[\"testResult\"]],[[[1,\"          \"],[10,0],[14,0,\"ai-tool-test-modal__test-result\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,[28,[32,1,[\"t\"]],[\"discourse_ai.tools.test_modal.result\"],null]],[13],[1,\"\\n            \"],[10,0],[12],[1,[30,0,[\"testResult\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]],[[[1,\"\\n        \"],[8,[32,4],[[24,0,\"btn-primary ai-tool-test-modal__run-button\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"runTest\"]],\"discourse_ai.tools.test_modal.run\",[30,0,[\"isLoading\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\",\"param\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/ai-tool-test-modal.js",
    "scope": () => [_dModal.default, _discourseI18n.default, _modifier.on, _helper.fn, _dButton.default],
    "isStrictMode": true
  }), _AiToolTestModal), _AiToolTestModal), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "testResult", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateParameter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateParameter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "runTest", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "runTest"), _class.prototype)), _class);
});