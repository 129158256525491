define("discourse/plugins/discourse-ai/discourse/components/ai-persona-tool-option-editor", ["exports", "@glimmer/component", "@ember/component", "@ember/modifier", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _modifier, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _AiPersonaToolOptionEditor;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let AiPersonaToolOptionEditor = _exports.default = (_class = (_AiPersonaToolOptionEditor = class AiPersonaToolOptionEditor extends _component.default {
    get isBoolean() {
      return this.args.option.type === "boolean";
    }
    get selectedValue() {
      return this.args.option.value.value === "true";
    }
    onCheckboxChange(event1) {
      this.args.option.value.value = event1.target.checked ? "true" : "false";
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="control-group ai-persona-tool-option-editor">
        <label>
          {{@option.name}}
        </label>
        <div class="">
          {{#if this.isBoolean}}
            <input
              type="checkbox"
              checked={{this.selectedValue}}
              {{on "click" this.onCheckboxChange}}
            />
            {{@option.description}}
          {{else}}
            <Input @value={{@option.value.value}} />
          {{/if}}
        </div>
        {{#unless this.isBoolean}}
          <div class="ai-persona-tool-option-editor__instructions">
            {{@option.description}}
          </div>
        {{/unless}}
      </div>
    
  */
  {
    "id": "3m5ogwIV",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"control-group ai-persona-tool-option-editor\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"\"],[12],[1,\"\\n\"],[41,[30,0,[\"isBoolean\"]],[[[1,\"          \"],[11,\"input\"],[16,\"checked\",[30,0,[\"selectedValue\"]]],[24,4,\"checkbox\"],[4,[32,0],[\"click\",[30,0,[\"onCheckboxChange\"]]],null],[12],[13],[1,\"\\n          \"],[1,[30,1,[\"description\"]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,1],null,[[\"@value\"],[[30,1,[\"value\",\"value\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"isBoolean\"]]],[[[1,\"        \"],[10,0],[14,0,\"ai-persona-tool-option-editor__instructions\"],[12],[1,\"\\n          \"],[1,[30,1,[\"description\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@option\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-persona-tool-option-editor.js",
    "scope": () => [_modifier.on, _component2.Input],
    "isStrictMode": true
  }), _AiPersonaToolOptionEditor), _AiPersonaToolOptionEditor), (_applyDecoratedDescriptor(_class.prototype, "onCheckboxChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCheckboxChange"), _class.prototype)), _class);
});